import {ElementPropTableColumn} from "@/mixins/elementPropTableColumn";

export const ElementScalePropTableColumn = {
    mixins: [ElementPropTableColumn],
    props: {
        scope: {type: Object, required: true},
        // isFieldAvailableForEdit: {type: Function, required: true},
    },
    watch: {},
    mounted() {},

    methods: {
        saveElementScaleData(element) {
            this.$api.card.saveElementScaleData({
                element_id: element.id,
                data: element.scale,
            })
                .then((data) => {
                    this.$notify.success({
                        title: 'Сохранено',
                        message: 'Элемент успешно сохранен'
                    });
                })
                .finally(() => {
                    this.$emit('elements-changed');
                })
        },
    }
}